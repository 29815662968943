<template>
  <div>
    <form @submit.prevent="submit" class="text-start default-form">
      <!-- ADVERTISER -->
      <div class="form-group mb-3" v-if="!advertiser">
        <label class="form-label fw-bold">
          Anunciante
          <span v-b-tooltip.hover title="Aqui você deve selecionar o anunciante que vai utilizar esse Pixel. Lembre-se cada anunciante deve ter um pixel só para ele."><i class="fa fa-question-circle"></i></span>
        </label>
        <div v-if="advertisersLoader">
          <Loader
            :size="'small'"
            :align="'start'"
          />
        </div>
        <div class="custom-select" v-else>
          <select class="form-control" v-model.trim="form.advertiser_id">
            <option disabled="true" selected="true">Selecione</option>
            <template v-if="advertisers.length > 0">
              <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
            </template>
          </select>
        </div>
      </div> <!-- END ADVERTISER -->
      <!-- NAME -->
      <div class="form-group mb-3">
        <label class="form-label fw-bold">
          Nome do Grupo de Pixels
          <span v-b-tooltip.hover title="Esse será o nome do grupo de pixels com todas as regras que você criar. Recomendamos usar o nome de seu cliente e/ou marca."><i class="fa fa-question-circle"></i></span>
        </label>
        <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.name" />
      </div> <!-- END NAME -->
      <!-- RULES -->
      <div>
        <h2 class="font-size-medium">Regras de pixel</h2>
        <p class="font-size-xSmall">
          <strong>Atenção:</strong> Uma vez criadas, regras não podem ser alteradas ou apagadas.
        </p>
        <div
          class="mb-3"
          v-for="(rule, index) in form.rules"
          :key="index"
        >
          <div class="d-flex align-items-end">
            <!-- NAME -->
            <div class="form-group col-lg-4 me-2">
              <label class="form-label fw-bold">
                Nome da Regra
                <span :id="`pixel-rule-name-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip :target="`pixel-rule-name-tooltip-${index}`" custom-class="font-size-xSmall">
                  Aqui você vai personalizar com o nome do que quer medir. Se é um pixel de Conversão que mede Leads, sugerimos o nome 'leads'. Se for um pixel de remarketing (usado para impactar novamente pessoas que entram no seu site), sugerimos um nome que ajuda a lembrar quem ele está medindo. Ex.: 'Rmkt todas as pessoas'.
                </b-tooltip>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Nome da regra"
                v-model.trim="form.rules[index].name"
                :disabled="form.rules[index].id"
              />
            </div> <!-- END NAME -->
            <!-- OBJECTIVE -->
            <div class="form-group col-lg-4 me-2">
              <label class="form-label fw-bold">
                Objetivo
                <span :id="`pixel-rule-objective-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip :target="`pixel-rule-objective-tooltip-${index}`" custom-class="font-size-xSmall">
                  Existem dois tipos de pixel: <br/>1) Conversão, usado para medir sempre que alguma ação desejada acontece como uma venda ou a geração de um cadastro. Ele deve ser instalado na página de sucesso que aparece logo após a conversão acontecer. <br/>2) Remarketing, usado para mapear pessoas que acessam seu site ou áreas específicas dele.
                </b-tooltip>
              </label>
              <div class="custom-select">
                <select
                  class="form-control"
                  v-model.trim="form.rules[index].objective"
                  :disabled="form.rules[index].id"
                >
                  <option value="" disabled>Selecione</option>
                  <option value="CONVERSION">Conversão</option>
                  <option value="RETARGETING">Retargeting</option>
                </select>
              </div>
            </div> <!-- END OBJECTIVE -->
            <div class="form-group col-lg-3">
              <!-- CONVERSION TYPE -->
              <label class="form-label fw-bold">
                Rótulo de evento
                <span :id="`pixel-rule-event-name-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip :target="`pixel-rule-event-name-tooltip-${index}`" custom-class="font-size-xSmall">
                  Use esse campo para identificar, no nível de detalhe máximo, o que você está medindo. Ex: "leadsite". Ou ainda "remarketingpaginaprincipal". Lembrando que esse campo não pode ter espaços e nem caracteres especiais
                </b-tooltip>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model.trim="form.rules[index].event_name"
                :disabled="form.rules[index].id"
              />
              <!-- END CONVERSIOn TYPE  -->
            </div>
            <!-- DESTROY RULE -->
            <div class="ms-2" v-if="!form.rules[index].id">
              <Button
                class="btn-danger btn-xs font-size-small btn-circle p-0 mb-2"
                @click.prevent="destroyRule(index)"
                v-b-tooltip.hover title="Remover regra"
              >
                <i class="fa fa-trash"></i>
              </Button>
            </div><!-- END DESTROY RULE -->
          </div>
          <p class="font-size-small mt-2"><strong>Importante:</strong> O nome do evento não pode ter espaços ou caracteres especiais.</p>
        </div>
        <div class="mt-3 text-center">
          <Button
            type="button"
            class="btn btn-color1 btn-sm"
            @click="addRule"
          >
            Adicionar regra
          </Button>
        </div>
      </div><!-- END RULES -->

      <!-- ACTIONS -->
      <div class="text-center mt-4">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            Essa ação pode levar alguns minutos.
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="submit"
          class="btn btn-primary"
          :loading="loader"
        >
          {{ pixel ? 'Editar' : 'Criar' }}
        </Button>
      </div> <!-- END ACTIONS -->
    </form>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import PixelsService from '@/modules/pixels/services/pixels-service'
import { parseErrorResponseToArray } from '@/utils'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'UpsertPixelForm',
  props: ['advertiser', 'pixel'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      loader: false,
      form: {
        advertiser_id: '',
        name: '',
        rules: []
      }
    }
  },
  created () {
    if (this.advertiser) this.form.advertiser_id = this.advertiser.id
    else this.getAdvertisers()

    if (this.pixel) {
      this.form.name = this.pixel.name
      this.pixel.rules.forEach(item => {
        this.form.rules.push({
          id: item.id,
          name: item.name,
          objective: item.objective,
          event_name: item.event_name
        })
      })
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Add rule
     */
    addRule () {
      this.form.rules.push({
        name: '',
        objective: '',
        event_name: ''
      })
    },
    /**
     * Destroy rule
     */
    destroyRule (index) {
      this.form.rules.splice(index, 1)
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        if (this.advertiser) {
          this.advertisers = advertisers.data.filter(item => {
            return item.id === this.advertiser.id
          })
        } else {
          this.advertisers = advertisers.data
        }
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.advertisersLoader = false
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        let pixel = null
        if (!this.pixel) {
          pixel = await PixelsService.createPixel({
            advertiserId: this.form.advertiser_id,
            name: this.form.name,
            rules: this.form.rules
          })
        } else {
          pixel = await PixelsService.updatePixel(this.pixel.id, {
            name: this.form.name,
            rules: this.form.rules.filter(item => {
              return !item.id
            })
          })
        }
        if (this.pixel) this.$emit('pixel-updated', pixel)
        else this.$emit('pixel-created', pixel)
      } catch (error) {
        // console.log(error)
        this.$emit('upsert-pixel-error', error)
      }
      this.loader = false
    }
  }
}
</script>
